import React from 'react';
import loadable from '@loadable/component';

import {
  LOGIN_ROUTE, LANDING_PAGE, REGISTRATION_START, SUPPORT_HOME,
} from 'constants/navigation';
import {
  LOGIN_ICON, HOME_NAV, REGISTER_ICON, SUPPORT_NAV,
} from 'constants/font-awesome';

import { Typography } from '@material-ui/core';
import useStyles from './styles';

const Page = loadable(() => import('components/page'));
const LazyLoginBanner = loadable(() => import('components/banners/login'));
const LazyLoginView = loadable(() => import('views/login'));
const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));

const SuspendedLoginPage = () => {
  const styles = useStyles();

  return (
    <Page
      title="Login"
      description="Login or Register to use the Total Loyalty App">
        <LazyLoginBanner/>
        <Typography variant="h2" component="h1" className={styles.heading} align="center">
          Welcome back to Total Loyalty
        </Typography>
        <LazyLoginView />
        <LazyOverrideFooter
          navItems={[
            { route: LANDING_PAGE, icon: HOME_NAV, message: 'Home' },
            { route: SUPPORT_HOME, icon: SUPPORT_NAV, message: 'Support' },
            { route: LOGIN_ROUTE, icon: LOGIN_ICON, message: 'Login' },
            { route: REGISTRATION_START, icon: REGISTER_ICON, message: 'Register' },
          ]}
          currentItem="Login"
          />
      </Page>
  );
};

export default SuspendedLoginPage;
